import { Component, Input, OnInit } from '@angular/core';
import { Location } from "@angular/common";

@Component({
  selector: 'app-back-nav-button',
  templateUrl: './back-nav-button.component.html',
  styleUrls: ['./back-nav-button.component.scss'],
})
export class BackNavButtonComponent implements OnInit {

  @Input('pageTitle') pageTitle: string;

  constructor(public _location: Location) {

  }

  ngOnInit() { }

  /**
   * Navigate back to previous page.
   *
   * @memberof ForgotPasswordPage
   */
  goBack(): void {
    this._location.back();
  }

}
