import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackNavButtonComponent } from './back-nav-button.component';

@NgModule({
    imports: [
        CommonModule
    ],
    entryComponents: [BackNavButtonComponent],
    declarations: [BackNavButtonComponent],
    exports: [BackNavButtonComponent]
})
export class BackNavButtonModule { }
