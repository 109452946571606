import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterShortComponent } from './footer-short.component';

@NgModule({
    imports: [
        CommonModule
    ],
    entryComponents: [FooterShortComponent],
    declarations: [FooterShortComponent],
    exports: [FooterShortComponent]
})
export class FooterShortModule { }
